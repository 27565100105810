import React from "react";
import { Link } from "react-router-dom";

// component
import Wave from "./Wave";

// image
import Me from "../images/me.png";

const AboutSection = () => {
  return (
    <div className="about-section">
      <div className="description">
        <div className="title">
          <div className="hide">
            <h1 className="title-first">
              Hi, I'm{" "}
              <span class="skew">
                <span class="unskew">Sagar</span>
              </span>
            </h1>
          </div>
          <div className="hide">
            <h1 className="title-second">A Web Developer.</h1>
          </div>
        </div>

        <div className="sub-title">
          <h2>
            My only goal is to make your business <span>grow</span>.
          </h2>
        </div>

        <p>
          I'm a Fullstack Web Developer based in Kolkata, India. I have a
          passion for design and I love to create Websites/WebApps with modern
          web technologies.
        </p>

        <div>
          <button className="btn">
            <Link
              to="/contact"
              style={{ textDecoration: "none", color: "white" }}
            >
              Contact Me
            </Link>
          </button>
        </div>
      </div>

      <div className="image">
        <div className="overlay"></div>
        <img src={Me} alt="" />
      </div>

      <Wave />
    </div>
  );
};

export default AboutSection;
