import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <h2>
        Thanks for visiting my website! <br /> If you have any questions,
        comments, or just want to say hi, feel free to reach out.
      </h2>
      <button className="btn">
        <Link to="/contact" style={{ textDecoration: "none", color: "white" }}>
          Contact Me
        </Link>
      </button>
    </div>
  );
};

export default Footer;
