import React from "react";

//components
import Navbar from "../components/Navbar";
import AboutSection from "../components/AboutSection";
import ServiceSection from "../components/ServiceSection";
import SkillSection from "../components/SkillSection";
import Footer from "../components/Footer";

const About = () => {
  return (
    <div className="about">
      <Navbar />
      <AboutSection />
      <SkillSection />
      <ServiceSection />
      <Footer />
    </div>
  );
};

export default About;
