import React, { useState } from "react";
import { Link } from "react-router-dom";

// images
import twitter from "../images/twitter.png";
import linkedin from "../images/linkedin.png";
import github from "../images/github.png";
import menu from "../images/menu.png";

const Navbar = () => {
  // state
  const [overlay, setOverlay] = useState(false);

  return (
    <nav>
      <div className={`${overlay ? "menu-overlay" : "hidden"}`}>
        <div onClick={() => setOverlay(false)} className="close-menu-overlay">
          X
        </div>
        <div className="links">
          <Link to="/" style={{ textDecoration: "none" }}>
            <h3 className="logo">Home</h3>
          </Link>
          <Link to="/projects" style={{ textDecoration: "none" }}>
            <h3 className="projects">Projects</h3>
          </Link>

          <Link to="/blogs" style={{ textDecoration: "none" }}>
            <h3 className="blogs">Blogs</h3>
          </Link>

          <Link to="/contact" style={{ textDecoration: "none" }}>
            <h3 className="contact">Contact Me</h3>
          </Link>
        </div>
      </div>

      <div className="navbar">
        <Link to="/" style={{ textDecoration: "none" }}>
          <h3 className="logo">Paul</h3>
        </Link>

        <div className="links">
          <Link to="/projects" style={{ textDecoration: "none" }}>
            <h3 className="projects">Projects</h3>
          </Link>

          <Link to="/blogs" style={{ textDecoration: "none" }}>
            <h3 className="blogs">Blogs</h3>
          </Link>

          <Link to="/contact" style={{ textDecoration: "none" }}>
            <h3 className="contact">Contact Me</h3>
          </Link>
        </div>

        <div className="social">
          <a
            href="https://twitter.com/iamSagarPaul"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitter} alt="" />
          </a>
          <a
            href="https://www.linkedin.com/in/sagar-paul-388812161"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="" />
          </a>
          <a
            href="https://github.com/SagarPaul007"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={github} alt="" />
          </a>

          <img
            onClick={() => setOverlay(true)}
            className={`menu ${overlay ? "hidden" : ""}`}
            src={menu}
            alt=""
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
