import React from "react";

// components
import Navbar from "../components/Navbar";

const Contact = () => {
  return (
    <>
      <Navbar />

      <div className="contact-section">
        <h1 className="heading">
          <span className="skew">
            <span className="unskew">Contact Form</span>
          </span>
        </h1>
        <form
          action="https://formsubmit.co/sagarpaul2k16@gmail.com"
          method="POST"
        >
          <input type="text" placeholder="Your Name" name="name" required />
          <input type="email" placeholder="Your Email" name="email" required />
          <textarea
            name="message"
            placeholder="Type your message"
            rows="10"
            cols="50"
          ></textarea>
          <input type="hidden" name="_captcha" value="false" />
          <button class="btn" type="submit">
            Send
          </button>
        </form>
      </div>
    </>
  );
};

export default Contact;
