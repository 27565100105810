import React, { useState, useEffect } from "react";

// components
import Navbar from "../components/Navbar";
import Blog from "../components/Blog";
import Footer from "../components/Footer";

const Blogs = () => {
  // state
  const [blogs, setBlogs] = useState([]);
  const [gif, setGif] = useState(
    "https://media.giphy.com/media/txh88yJygPWC1q3rtd/giphy.gif"
  );

  useEffect(() => {
    async function gql(query, variables = {}) {
      const data = await fetch("https://api.hashnode.com/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
          variables,
        }),
      });

      return data.json();
    }

    const GET_USER_ARTICLES = `
      query GetUserArticles($page: Int!) {
          user(username: "iamSagarPaul") {
              publication {
                  posts(page: $page) {
                      title
                      brief
                      slug
                      coverImage
                  }
              }
          }
      }
  `;

    gql(GET_USER_ARTICLES, { page: 0 }).then((result) => {
      const articles = result.data.user.publication.posts;
      setBlogs(articles);
      setGif("");
    });
  }, []);

  return (
    <div>
      <Navbar />
      <div className="blogs-section">
        <h1>
          <span className="skew">
            <span className="unskew">My blogs</span>
          </span>
        </h1>

        <div className="loading">
          <img src={gif} alt="" />
        </div>

        <div className="blogs">
          {blogs.map((blog) => (
            <Blog blog={blog} key={blog.slug} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blogs;
