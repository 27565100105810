import React from "react";

const Blog = ({ blog }) => {
  return (
    <div className="blog">
      <img src={blog.coverImage} alt="" />
      <h2 className="blog-title">{blog.title}</h2>
      <p className="blog-brief">{blog.brief}</p>
      <a
        href={`https://spaul.hashnode.dev/${blog.slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Read More
      </a>
    </div>
  );
};

export default Blog;
