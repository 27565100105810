import React from "react";

const ServiceSection = () => {
  return (
    <div className="services">
      <h1>
        <span className="skew">
          <span className="unskew">What I can Do</span>
        </span>
      </h1>

      <div className="design">
        <img
          src="https://media.giphy.com/media/l378yjDKofRszKaAw/giphy.gif"
          alt=""
        />
        <div className="details">
          <h2>Design what you want</h2>
          <p>
            I like to keep it simple. My goals are to focus on typography,
            content and conveying the message that you want to send.
          </p>
        </div>
      </div>

      <div className="develop">
        <img
          src="https://media.giphy.com/media/U3rYyVWOhXp64Yxr3r/giphy.gif"
          alt=""
        />
        <div className="details">
          <h2>Develop what you need</h2>
          <p>
            I'm a developer, so I know how to create your website to run across
            devices using the latest technologies available.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
