import React from "react";

// images
import html from "../images/html.png";
import css from "../images/css.png";
import bootstrap from "../images/bootstrap.png";
import sass from "../images/sass.png";
import js from "../images/js.png";
import react from "../images/react.png";
import node from "../images/node.png";
import mongodb from "../images/mongodb.png";
import git from "../images/git.png";

const SkillSection = () => {
  return (
    <div className="skill-section">
      <h1>
        <span className="skew">
          <span className="unskew">My Skills</span>
        </span>
      </h1>
      <div className="skills">
        <div className="html skill">
          <img src={html} alt="html" />
          <h2>HTML</h2>
        </div>
        <div className="css skill">
          <img src={css} alt="css" />
          <h2>CSS</h2>
        </div>
        <div className="bootstrap skill">
          <img src={bootstrap} alt="bootstrap" />
          <h2>Bootstrap</h2>
        </div>
        <div className="sass skill">
          <img src={sass} alt="sass" />
          <h2>SCSS/SASS</h2>
        </div>
        <div className="js skill">
          <img src={js} alt="js" />
          <h2>JavaScript</h2>
        </div>
        <div className="react skill">
          <img src={react} alt="react" />
          <h2>React</h2>
        </div>
        <div className="node skill">
          <img src={node} alt="node" />
          <h2>Node</h2>
        </div>
        <div className="mongodb skill">
          <img src={mongodb} alt="mongodb" />
          <h2>MongoDB</h2>
        </div>
        <div className="git skill">
          <img src={git} alt="git" />
          <h2>Git</h2>
        </div>
      </div>
    </div>
  );
};

export default SkillSection;
