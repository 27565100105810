import React from "react";

// components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// images
import pandora from "../images/project images/pandora.png";
import vidr from "../images/project images/vidr.png";
import vimo from "../images/project images/vimo.png";
import weather from "../images/project images/weather.png";
import meaning from "../images/project images/meaning.png";
import bookworm from "../images/project images/bookworm.png";
import fooody from "../images/project images/fooody.png";
import quizly from "../images/project images/quizly.png";

const Projects = () => {
  return (
    <>
      <Navbar />
      <div className="projects-section">
        <h1 className="heading">
          <span className="skew">
            <span className="unskew">My Projects</span>
          </span>
        </h1>

        <div className="projects">
          {/* pandora */}
          <div className="project">
            <img src={pandora} alt="" />
            <h2 className="project-title">Pandora</h2>
            <p className="project-description">
              Pandora is a movie/series/books/songs downloader webapp. I do not
              promote piracy. This project was just for fun. Technologies used
              React, Node/Express, cheerio.
            </p>
            <div className="project-links">
              <a
                href="https://pandora-sp.netlify.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live
              </a>
              <a
                href="https://github.com/SagarPaul007/pandora"
                target="_blank"
                rel="noopener noreferrer"
              >
                Code
              </a>
            </div>
          </div>

          {/* vidr */}
          <div className="project">
            <img src={vidr} alt="" />
            <h2 className="project-title">Vidr</h2>
            <p className="project-description">
              Vidr is a youtube video downloader. Different Audio/Video formats
              available. Technologies used: React, SCSS, Node, Express,
              ytdl-core.
            </p>
            <div className="project-links">
              <a
                href="https://vidr-sp.netlify.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live
              </a>
              <a
                href="https://github.com/SagarPaul007/Vidr"
                target="_blank"
                rel="noopener noreferrer"
              >
                Code
              </a>
            </div>
          </div>

          {/* vimo */}
          <div className="project">
            <img src={vimo} alt="" />
            <h2 className="project-title">Vimo</h2>
            <p className="project-description">
              Download YIFY movies in hd/fullhd/4k formats. This project was
              just for fun, I do not promote piracy. Technologies used: React,
              SCSS, Node, Cheerio.
            </p>
            <div className="project-links">
              <a
                href="https://vimo-sp.netlify.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live
              </a>
              <a
                href="https://github.com/SagarPaul007/Vimo"
                target="_blank"
                rel="noopener noreferrer"
              >
                Code
              </a>
            </div>
          </div>

          {/* weather */}
          <div className="project">
            <img src={weather} alt="" />
            <h2 className="project-title">Show my weather</h2>
            <p className="project-description">
              See weather information of any location. Technologies used HTML,
              CSS, JavaScript, Node, Express.
            </p>
            <div className="project-links">
              <a
                href="https://showmyweather.herokuapp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live
              </a>
              <a
                href="https://github.com/SagarPaul007/weather-app-nodejs"
                target="_blank"
                rel="noopener noreferrer"
              >
                Code
              </a>
            </div>
          </div>

          {/* meaning */}
          <div className="project">
            <img src={meaning} alt="" />
            <h2 className="project-title">Meaning</h2>
            <p className="project-description">
              English Dictionary, Find meaning of any word. Technologies used
              HTML, CSS, Javascript.
            </p>
            <div className="project-links">
              <a
                href="https://meaning.vercel.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live
              </a>
              <a
                href="https://github.com/SagarPaul007/Meaning-App"
                target="_blank"
                rel="noopener noreferrer"
              >
                Code
              </a>
            </div>
          </div>

          {/* bookworm */}
          <div className="project">
            <img src={bookworm} alt="" />
            <h2 className="project-title">Bookworm</h2>
            <p className="project-description">
              Search and read available books. Technologies used: HTML, CSS,
              Javascript.
            </p>
            <div className="project-links">
              <a
                href="https://bookworm-sp.vercel.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live
              </a>
              <a
                href="https://github.com/SagarPaul007/BookWorm"
                target="_blank"
                rel="noopener noreferrer"
              >
                Code
              </a>
            </div>
          </div>

          {/* fooody */}
          <div className="project">
            <img src={fooody} alt="" />
            <h2 className="project-title">Fooody</h2>
            <p className="project-description">
              Meal finder, search recipes. Technologies used: HTML, CSS,
              JavaScript.
            </p>
            <div className="project-links">
              <a
                href="https://fooody.vercel.app/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live
              </a>
              <a
                href="https://github.com/SagarPaul007/Fooody"
                target="_blank"
                rel="noopener noreferrer"
              >
                Code
              </a>
            </div>
          </div>

          {/* quizly */}
          <div className="project">
            <img src={quizly} alt="" />
            <h2 className="project-title">Quizly</h2>
            <p className="project-description">
              Play quiz, trivia. Answer 20 mcq questions. Technologies used:
              HTML, CSS, JavaScript.
            </p>
            <div className="project-links">
              <a
                href="https://quizlytrivia.vercel.app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Live
              </a>
              <a
                href="https://github.com/SagarPaul007/Quizly"
                target="_blank"
                rel="noopener noreferrer"
              >
                Code
              </a>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Projects;
